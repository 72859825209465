:root {
  --green-500: #00a008;
  --yellow-500: #f4af0c; 
  --blue-500: #5081F6; 
  --blue-700: #345bb7; 
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #26273d;
  color: #8788b0;
}

body,
input,
button,
textarea {
  font: 400 16px "Roboto", sans-serif;
  outline: none;
}

button {
  min-height: 38px;
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: center;
  align-items: center;
  padding: 0px 15px;
}

::-webkit-scrollbar {
  width: 3px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
  background-clip: padding-box;
  border-radius: 9999px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::placeholder {
  color: #9d9fc5;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #9d9fc5;
}

::-ms-input-placeholder {
  color: #9d9fc5;
}

button:hover {
  opacity: 0.75;
}

select {
  height: 45px;
  width: 100%;
  border-radius: 5px;
  padding: 4px 16px;
  font-size: 14px;
  background: #63658b;
  color: #C0C0C0;
}

hr {
  border: 1px solid #8788b0;
}