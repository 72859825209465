.development {
  width: 100%;
  text-align: center;
  padding: 20px;
  font-weight: 600;
  font-size: 18px;
  color: #FFF;
  background: black;
}

.topoSite {
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
  width: 100%;
  background-color: #294484;
  height: 180px;
  border-top: 12px solid var(--yellow-500);
  border-bottom: 5px solid #fff;
  padding: 6px 15px;
}

.topoSite .columnLeft {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-right: 80px;
}

.topoSite .columnLeft div:nth-child(1) {
  flex: 1;
}

.topoSite .columnLeft p:nth-child(1) {
  color: #fff;
  font-size: 1.1rem;
  font-weight: 500;
  width: 100%;
  text-align: right;
}

.topoSite .columnLeft p:nth-child(2) {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  text-align: right;
}

.topoSite .columnLeft div:nth-child(2) {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.topoSite .columnLeft div:nth-child(2) svg {
  color: var(--yellow-500);
  font-size: 30px;
  cursor: pointer;
}


.topoSite .columnRight {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  margin-left: 80px;
}

.topoSite .columnRight div svg {
  margin-right: 5px;
}

.topoSite .columnRight div {
  display: flex;
  flex-direction: row;
  gap: 7px;
  font-weight: 500;
}

.topoSite .columnRight div:nth-child(2) {
  gap: 3px;
}

.topoSite .columnRight div:nth-child(1) {
  margin-top: 5px;
  font-size: 18px;
  color: #fff;
}

.topoSite .columnRight div:nth-child(1) a {
  color: #fff;
  text-decoration: none;
}

.topoSite .columnRight div:nth-child(2) a {
  color: #fff;
  text-decoration: none;
}

.topoSite .columnRight div:nth-child(2) {
  margin-top: 5px;
  font-size: 12px;
}

.topoSite .columnLogo img {
  cursor: pointer;
}

.menuSite {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 75px;
  background: #5081F6;
  gap: 15px;
  padding: 0px 15px;
}

.menuSite div {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #FFF;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.5s;
}

.menuSite div:hover {
  color: #345bb7;
}

.menuSite div svg {
  color: #345bb7;
  font-size: 20px;
}

.menuSite div .search {
  border-radius: 8px;
  background: #FFF;
  height: 48px;
  width: 350px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 10px;
  gap: 5px;
}

.menuSite div .search input {
  height: 40px;
  flex: 1;
  outline: none;
  border: none;
}

.menuSite div .search svg {
  font-size: 22px;
  color: black;
  cursor: pointer;
}

.banners {
  border-bottom: 5px solid #E0E0E0;
}

.banners img {
  width: 100%;
}

.bodySite {
  background-color: #8FE2FF;
  padding-bottom: 50px;
}

.bodySite .contentBody {
  max-width: 1300px;
  background: #FFF;
  border-radius: 0px 0px 8px 8px;
  margin: 0 auto;
  padding: 25px 15px;
}

.bodySite .dev {
  font-size: 14px;
  opacity: 0.75;
  color: #000;
  text-align: center;
  margin-top: 10px;
  text-shadow: 1px 0 0 #fff, -1px 0 0 #fff, 0 1px 0 #fff, 0 -1px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff;
}

.sideBar {
  display: none;
  width: 100%;
  font-size: 25px;
  color: #FFF;
}

#menuSideBar {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  opacity: 0;
  height: 100vh;
  z-index: 9;
  background: #505050;
  transition: all 0.5s;
}

#menuSideBar .closeMenu {
  font-size: 22px;
  position: absolute;
  right: 10px;
  top: 5px;
  cursor: pointer;
  color: #FFF;
}

#menuSideBar h2 {
  background: #345bb7;
  text-align: center;
  padding: 10px 0px;
  color: #FFF;
}

#menuSideBar div {
  display: flex;
  flex-direction: row;
  gap: 5px;
  padding: 10px;
  color: #FFF;
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .menuSite {
    display: none;
  }

  .sideBar {
    display: block;
    cursor: pointer;
  }

  .topoSite {
    height: 350px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .topoSite .columnLeft {
    margin: 0px;
    justify-content: center;
    align-items: center;
  }

  .topoSite .columnRight {
    margin: 0px;
    justify-content: center;
    align-items: center;
  }

}

.divWhatsapp {
  position: fixed;
  right: 10px;
  bottom: 10px;
  width: 60px;
  height: 60px;
  background: #25D366;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 40px;
  cursor: pointer;
}