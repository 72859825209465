#pageIngressos {
  display: flex;
  flex-direction: column;
  padding: 15px;
  min-width: 800px;
}

#pageIngressos .btnVoltar {
  width: 150px;
  align-self: flex-end;
}

#pageIngressos .tableContext {
  margin-top: 25px;
  width: 100%;
  background: #1f2139;
  border-radius: 8px;
  box-shadow: 1px 1px 3px #18182c;
  padding: 15px;
}

#pageIngressos .tableContext .headTable {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 500;
  border-bottom: 1px solid #8788b0;
  font-size: 18px;
}

#pageIngressos .tableContext .headTable span {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

#pageIngressos .tableContext .contentTable {
  width: 100%;
}

#pageIngressos .tableContext .contentTable .rowTable {
  display: flex;
  flex-direction: row;
  padding: 15px 0px;
  justify-content: space-between;
  padding: 10px 10px;
  font-size: 14px;
  align-items: center;
  gap: 10px;
}

#pageIngressos .tableContext .contentTable .rowTable span {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

#pageIngressos .tableContext .contentTable .rowTable:hover {
  background: #18182c;
  border-radius: 8px;
}

#pageIngressos .tableContext .form {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 15px;
  gap: 10px;
}

#pageIngressos .pagination {
  background-color: #1f2139;
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 10px;
  border-radius: 8px;
  width: 120px;
  margin-top: 20px;
}

#pageIngressos .pagination p {
  margin: 0px 8px;
  font-size: 15px;
  font-weight: 500;
}

#pageIngressos .tableContext .eventoDetail {
  width: 100%;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  gap: 10px;
}

#pageIngressos .tableContext .eventoDetail .infoEvento {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
}

#pageIngressos .tableContext .eventoDetail .infoEvento h2 {
  font-size: 22px;
  font-weight: 500;
}

#pageIngressos .tableContext .eventoDetail .infoEvento b {
  font-weight: 500;
  font-size: 16px;
}

#pageIngressos .tableContext .contextIngressos {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

#pageIngressos .tableContext .contextIngressos .cardIngresso {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  border: 2px dashed #4b4d6e;
  border-radius: 5px;
  padding: 10px;
}

#pageIngressos .tableContext .contextIngressos .cardIngresso:hover {
  color: #fff;
  border: 2px dashed #fff;
}

#pageIngressos .tag {
  width: 125px;
  padding: 5px;
  margin: 0 auto;
  border-radius: 5px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
}

#pageIngressos .paid {
  background-color: #e4f7c8;
  color: #4f7f00;
}

#pageIngressos .used {
  background-color: #f1d7c5;
  color: #eb701e;
}

#pageIngressos .failed {
  background-color: #fde0e7;
  color: #a71a44;
}

#pageIngressos .pending {
  background-color: #e2efff;
  color: #235894;
}

#pageIngressos .btnUtilizado {
  border-radius: 5px;
}