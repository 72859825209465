#pageIngresso {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#pageIngresso h4 {
  margin-top: 10px;
}