.TopoAdm {
  width: 100%;
  height: 80px;
  background: #2f2e4b;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.TopoAdm .logo {
  width: 150px;
  height: 100%;
  border-right: 2px solid #26273d;
}

.TopoAdm .faixaTopo {
  flex: 1;
  padding: 0 25px;

  display: flex;
  flex-direction: row;
}

.TopoAdm .faixaTopo h2 {
  flex: 1;
}

.TopoAdm .faixaTopo div {
  width: 150px;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
}

.layoutContent {
  width: 100%;
  height: calc(100vh - 80px);

  display: flex;
  flex-direction: row;
}

.layoutContent nav {
  padding-top: 15px;
  width: 150px;
  height: 100%;
  opacity: 1;
  overflow: auto;
  background: #232538;
  transition: all 0.5s;

  display: flex;
  flex-direction: column;
}

.layoutContent nav .navLink {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  align-items: center;
  font-size: 14px;
  text-decoration: none;
  color: #8788b0;
  width: 100%;
  height: 80px;
  cursor: pointer;
  transition: all 0.5s;
}

.layoutContent nav .navLink:hover {
  background: #1e2030;
}

.layoutContent nav .selected {
  background: #18182c;
  border-left: 3px solid #ff8914;
  color: #ff8914;
}

.layoutContent nav .selected p {
  color: #8788b0;
}

.layoutContent .layoutContext {
  flex: 1;
  overflow: auto;
  box-shadow: 1px 1px 8px #1b1c32 inset;
}

.layoutContent .layoutContext .btn-green {
  border: 0px;
  cursor: pointer;
  border-radius: 5px;
  color: #ecfcfa !important;
  background: #00e0aa !important;
}

.layoutContent .layoutContext .btn-blue {
  border: 0px;
  cursor: pointer;
  border-radius: 5px;
  color: #ecfcfa !important;
  background: #00bcf1 !important;
}

.layoutContent .layoutContext .btn-purple {
  border: 0px;
  cursor: pointer;
  border-radius: 5px;
  color: #ecfcfa !important;
  background: #6f66ff !important;
}

.layoutContent .layoutContext .btn-red {
  border: 0px;
  cursor: pointer;
  border-radius: 5px;
  color: #ecfcfa !important;
  background: #ff5349 !important;
}

#menuUser {
  position: absolute;
  right: 25px;
  top: 50px;
  background: #FFF;
  border-radius: 8px 0px 8px 8px;
  transition: all 0.5s;
  z-index: 4;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

#menuUser p {
  text-align: center;
  padding: 5px;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
}

#menuUser p:hover {
  background: #f1f1f1;
}

.iconUser {
  transition: all 0.5s;
}

.toggleOpen {
  padding: 5px 10px;
  background: #FFF;
  color: #2f2e4b;
  border-radius: 8px 8px 0px 0px;
}

#chevronLeft {
  position: absolute;
  top: 90px;
  left: 10px;
  text-align: right;
  transition: all 0.5s;
  cursor: pointer;
  z-index: 9;
}