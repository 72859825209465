#pageConfigSocio {
  display: flex;
  flex-direction: column;
  padding: 15px;
  min-width: 800px;
}

#pageConfigSocio .configContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 25px;
  width: 100%;
  background: #1f2139;
  border-radius: 8px;
  box-shadow: 1px 1px 3px #18182c;
  padding: 15px;
}

#pageConfigSocio textarea {
  height: 150px;
  width: 100%;
  border: 0;
  border-radius: 5px;
  padding: 4px 16px;
  font-size: 14px;
  background: #63658b;
  color: #C0C0C0;
}

#pageConfigSocio .configContent button {
  margin: 0 auto;
}


#pageConfigSocio .tableContext {
  width: 100%;
}

#pageConfigSocio .tableContext .headTable {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 500;
  border-bottom: 1px solid #8788b0;
  font-size: 18px;
}

#pageConfigSocio .tableContext .headTable span {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

#pageConfigSocio .tableContext .contentTable {
  width: 100%;
}

#pageConfigSocio .tableContext .contentTable .rowTable {
  display: flex;
  flex-direction: row;
  padding: 15px 0px;
  justify-content: space-between;
  padding: 10px 10px 10px 0px;
  font-size: 14px;
  align-items: center;
}

#pageConfigSocio .tableContext .contentTable .rowTable span {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

#pageConfigSocio .tableContext .contentTable .rowTable:hover {
  background: #18182c;
  border-radius: 8px;
}

#pageConfigSocio .statusOn {
  background-color: #076b07;
  border-radius: 50%;
  width: 25px;
  height: 25px;
}

#pageConfigSocio .statusOff {
  background-color: #af0000;
  border-radius: 50%;
  width: 25px;
  height: 25px;
}