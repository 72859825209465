#modalEventoImage {
  display: flex;
  flex-direction: column;
  width: 100%;
}

#modalEventoImage .title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  background-color: #18182c;
  color: #FFF;
  font-size: 22px;
  font-weight: 550;
}

#modalEventoImage .body {
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: 15px 0;
  align-items: center;
}

#modalEventoImage .dropFile {
  width: 100%;
  height: 200px;
  border: dashed 2px #8788b0;
}

#modalEventoImage .dropFile .fVfcRr > span {
  color: #8788b0;
}

#modalEventoImage .dropFile svg {
  display: none;
}