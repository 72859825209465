#pageEventoDetalhes .bodyEvento {
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: center;
  align-items: flex-start;
  color: #333333;
}

#pageEventoDetalhes .bodyEvento div:nth-child(1) {
  width: 350px;
}

#pageEventoDetalhes .bodyEvento img {
  width: 350px;
}

#pageEventoDetalhes .bodyEvento div:nth-child(2) {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

#pageEventoDetalhes .setores {
  margin-top: 15px;
}

#pageEventoDetalhes .setores h2 {
  margin: 0 auto;
  text-align: center;
  color: #333;
  border-top: 5px solid #345bb7;
  border-bottom: 5px solid #345bb7;
  padding: 2px 15px;
  width: 150px;
}

#pageEventoDetalhes .setores .content {
  display: flex;
  flex-direction: row;
  gap: 15px;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 15px;
}

#pageEventoDetalhes .setores .content .cardSetor {
  border-radius: 8px;
  border: 2px dashed #ccc;
  padding: 15px 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

#pageEventoDetalhes .setores .content .cardSetor:hover {
  border: 2px dashed #333;
  color: #333;
}


#pageEventoDetalhes .setores .content .selected {
  border: 2px dashed #345bb7;
  color: #345bb7;
}

#pageEventoDetalhes .setores .content .cardSetor p {
  margin-top: 5px;
  font-size: 14px;
}

#pageEventoDetalhes .lotes {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
}

#pageEventoDetalhes .lotes h2 {
  margin: 0 auto;
  text-align: center;
  color: #333;
  border-top: 5px solid #345bb7;
  border-bottom: 5px solid #345bb7;
  padding: 2px 15px;
  width: 150px;
}

#pageEventoDetalhes .lotes .cardLote {
  width: 45%;
  padding: 15px 20px;
  border-radius: 5px;
  background-color: #ededed;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

#pageEventoDetalhes .lotes .cardLote .titleSelecMesa {
  width: 100%;
  margin: 15px 0 0 8px;
  font-size: 14px;
  font-weight: 500;
}

#pageEventoDetalhes .lotes .cardLote .mesas {
  width: 100%;
  margin: 5px 0px 0px;
  background: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

#pageEventoDetalhes .lotes .cardLote .mesas span {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  color: #FFF;
  font-size: 22px;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #b0bcc5;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

#pageEventoDetalhes .lotes .cardLote .mesas .sold {
  cursor: not-allowed;
  display: flex;
  flex-direction: column;
  color: #FFF;
  font-size: 22px;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #26211d;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

#pageEventoDetalhes .lotes .cardLote .mesas .selected {
  cursor: default;
  display: flex;
  flex-direction: column;
  color: #FFF;
  font-size: 22px;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #345bb7;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

#pageEventoDetalhes .lotes .cardLote .mesas span:hover {
  opacity: 0.9;
}

#pageEventoDetalhes .lotes .cardLote div:nth-child(1) {
  flex: 1;
}

#pageEventoDetalhes .lotes .cardLote div p {
  font-size: 14px;
  margin-top: 5px;
}

#pageEventoDetalhes .lotes .cardLote button {
  font-family: Helvetica;
  color: #FFF;
  background-color: var(--yellow-500);
  font-weight: 600;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

#pageEventoDetalhes .lotes .cardLote div button:disabled {
  background: #b1b1b1;
}

#pageEventoDetalhes .carrinho {
  font-family: Helvetica;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
}

#pageEventoDetalhes .carrinho .infoComprarMais {
  text-align: center;
  margin: 0 auto;
  border: 2px dashed #9688b0;
  border-radius: 5px;
  padding: 5px 10px;
}

#pageEventoDetalhes .carrinho h2 {
  margin: 0 auto;
  text-align: center;
  color: #333;
  border-top: 5px solid #345bb7;
  border-bottom: 5px solid #345bb7;
  padding: 2px 15px;
  width: 150px;
}

#pageEventoDetalhes .carrinho .cardCarrinho {
  width: 100%;
  padding: 15px 20px;
  border-radius: 5px;
  background-color: #ededed;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}

#pageEventoDetalhes .carrinho .cardCarrinho button {
  border: 0px;
  cursor: pointer;
  border-radius: 5px;
  color: #ecfcfa;
  background: #345bb7;
}

#pageEventoDetalhes .carrinho .cardCarrinho div:nth-child(2) {
  width: 40px;
  text-align: center;
}

#pageEventoDetalhes .carrinho .cardCarrinho div:nth-child(3) {
  width: 100px;
  text-align: center;
}

#pageEventoDetalhes .carrinho .cardCarrinho:hover {
  opacity: 0.8;
}

#pageEventoDetalhes .carrinho .faixaTotal {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px;
}

#pageEventoDetalhes .carrinho .faixaTotal p {
  font-weight: 600;
  font-size: 20px;
}

#pageEventoDetalhes .carrinho .faixaTotal button {
  font-family: Helvetica;
  color: #FFF;
  background: var(--yellow-500);
  font-weight: 600;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 15px 40px;
}

@media only screen and (max-width: 767px) {

  #pageEventoDetalhes .bodyEvento {
    flex-direction: column;
  }

  #pageEventoDetalhes .lotes .cardLote {
    flex-direction: column;
    gap: 15px;
    width: 100%;
  }

  #pageEventoDetalhes .carrinho .cardCarrinho {
    flex-direction: column;
  }

  #pageEventoDetalhes .bodyEvento div:nth-child(1) {
    width: 100%;
  }

  #pageEventoDetalhes .bodyEvento img {
    width: 100%;
  }

  #pageEventoDetalhes .carrinho .faixaTotal {
    flex-direction: column;
    gap: 10px;
  }

}