#pageLoginCliente .sectionPhone {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#pageLoginCliente .sectionPhone .iconLogin {
  margin: 15px 0;
  font-size: 60px;
}

#pageLoginCliente .sectionPhone h2 {
  margin-bottom: 15px;
}

#pageLoginCliente .sectionPhone .inputLogin {
  width: 300px;
  height: 40px;
  display: flex;
  flex-direction: row;
  background: #dedede;
  border-radius: 20px;
  align-items: center;
  padding: 0px 15px;
  gap: 8px;
}

#pageLoginCliente .sectionPhone .inputLogin svg {
  font-size: 20px;
}

#pageLoginCliente .sectionPhone .inputLogin input {
  width: 100%;
  height: 35px;
  border: none;
  outline: none;
  background: transparent;
  font-size: 16px;
}

#pageLoginCliente .sectionPhone button {
  margin-top: 15px;
  border: 0px;
  cursor: pointer;
  border-radius: 20px;
  color: #ecfcfa !important;
  background: #00e0aa !important;
  padding: 5px 25px;
}

#pageLoginCliente .pLink {
  margin-top: 5px;
  cursor: pointer;
  font-size: 12px;
}