#pageCarrinho {
  font-family: Helvetica;
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
}

#pageCarrinho button {
  font-family: Helvetica;
  color: #FFF;
  background-color: var(--yellow-500);
  font-weight: 600;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 15px 20px;
}

#pageCarrinho .btnVoltar {
  font-family: Helvetica;
  color: #FFF;
  background: #ff5349;
  font-weight: 600;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 15px 40px;
}

#pageCarrinho h2 {
  margin: 0 auto;
  text-align: center;
  color: #333;
  border-top: 5px solid #294484;
  border-bottom: 5px solid #294484;
  padding: 2px 25px;
}

#pageCarrinho .cardCarrinho {
  width: 100%;
  padding: 15px 20px;
  border-radius: 5px;
  background-color: #ededed;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}

#pageCarrinho .cardCarrinho button {
  border: 0px;
  cursor: pointer;
  border-radius: 5px;
  color: #ecfcfa;
  background-color: #294484;
}

#pageCarrinho .cardCarrinho div:nth-child(2) {
  width: 40px;
  text-align: center;
}

#pageCarrinho .cardCarrinho div:nth-child(3) {
  width: 100px;
  text-align: center;
}

#pageCarrinho .cardCarrinho:hover {
  opacity: 0.8;
}

#pageCarrinho .faixaTotal {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px;
}

#pageCarrinho .faixaTotal p {
  font-weight: 600;
  font-size: 20px;
}

#pageCarrinho .faixaTotal .btnContext {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

#pageCarrinho .faixaTotal .btnContext .btnComprarMais {
  background-color: #6f66ff;
}

#pageCarrinho .formasPgto {
  margin-top: 15px;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: center;
  align-items: center;
}

#pageCarrinho .formasPgto .cardPgto {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 150px;
  height: 150px;
  background: #eee;
  border-radius: 8px;
  padding: 15px;
  cursor: pointer;
}

#pageCarrinho .formasPgto .cardPgto:hover {
  border: 2px dashed #26273d;
  /* border-style: ; */
  color: #26273d;
}

#pageCarrinho .formasPgto .cardPgto svg {
  font-size: 40px;
}

#pageCarrinho .formasPgto .cardPgto p {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}

#pageCarrinho .divForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  align-items: center;
}

#pageCarrinho .inputForm {
  width: 300px;
  height: 40px;
  display: flex;
  flex-direction: row;
  background: #dedede;
  border-radius: 20px;
  align-items: center;
  padding: 0px 15px;
  gap: 8px;
}

#pageCarrinho .inputForm svg {
  font-size: 20px;
}

#pageCarrinho .inputForm input {
  width: 100%;
  height: 35px;
  border: none;
  outline: none;
  background: transparent;
  font-size: 16px;
}

@media only screen and (max-width: 767px) {
  #pageCarrinho .faixaTotal {
    flex-direction: column;
    gap: 10px;
  }

  #pageCarrinho .cardCarrinho {
    flex-direction: column;
  }
}

#pageCarrinho select {
  width: 100%;
  height: 35px;
  border: none;
  outline: none;
  background: transparent;
  font-size: 16px;
  color: #26273d;
}