#pageEventosAdm {
  display: flex;
  flex-direction: column;
  padding: 15px;
  min-width: 800px;
}

#pageEventosAdm .btnNovo {
  width: 150px;
  align-self: flex-end;
}

#pageEventosAdm .tableContext {
  margin-top: 25px;
  width: 100%;
  background: #1f2139;
  border-radius: 8px;
  box-shadow: 1px 1px 3px #18182c;
  padding: 15px;
}

#pageEventosAdm .tableContext .headTable {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 500;
  border-bottom: 1px solid #8788b0;
  font-size: 18px;
}

#pageEventosAdm .tableContext .headTable span {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

#pageEventosAdm .tableContext .contentTable {
  width: 100%;
}

#pageEventosAdm .tableContext .contentTable .rowTable {
  display: flex;
  flex-direction: row;
  padding: 15px 0px;
  justify-content: space-between;
  padding: 10px 10px;
  font-size: 14px;
  align-items: center;
  gap: 10px;
}

#pageEventosAdm .tableContext .contentTable .rowTable span {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

#pageEventosAdm .tableContext .contentTable .rowTable:hover {
  background: #18182c;
  border-radius: 8px;
}

#pageEventosAdm .tableContext .form {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 15px;
  gap: 10px;
}

#pageEventosAdm .pagination {
  background-color: #1f2139;
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 10px;
  border-radius: 8px;
  width: 120px;
  margin-top: 20px;
}

#pageEventosAdm .pagination p {
  margin: 0px 8px;
  font-size: 15px;
  font-weight: 500;
}