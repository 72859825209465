#modalEventoSetores {
  display: flex;
  flex-direction: column;
  width: 100%;
}

#modalEventoSetores .title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  background-color: #18182c;
  color: #FFF;
  font-size: 22px;
  font-weight: 550;
}

#modalEventoSetores .headTable {
  margin: 15px 15px 0px 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #18182c;
  padding: 10px 10px;
  color: #FFF;
  font-weight: 500;
  border-radius: 5px 5px 0px 0px;
}

#modalEventoSetores .contentTable {
  margin: 0px 15px 15px 15px;
  border: 1px solid #18182c;
  min-height: 300px;
  border-radius: 0px 0px 5px 5px;
}

#modalEventoSetores .rowTable {
  display: flex;
  flex-direction: row;
  padding: 15px 0px;
  justify-content: space-between;
  padding: 10px 10px;
  color: #888;
  font-size: 14px;
  align-items: center;
}

#modalEventoSetores .rowTable:hover {
  background: #18182c;
}

#modalEventoSetores .rowTable span{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

#modalEventoSetores .rowTable span button {
  font-size: 14px;
  height: 30px;
  width: 30px;
  border: 0px;
  border-radius: 5px;
  color: #FFF;
  cursor: pointer;
}

#modalEventoSetores .body {
  height: calc(100vh - 115px);
  overflow: auto;
}

#modalEventoSetores .body .divFormModal{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 10px 15px;
  gap: 10px;
}

#modalEventoSetores .body .divFormModal button{
  font-size: 20px;
  padding: 0px 15px;
}

#modalEventoSetores .btn-green {
  border: 0px;
  cursor: pointer;
  border-radius: 5px;
  color: #ecfcfa !important;
  background: #00e0aa !important;
}

#modalEventoSetores .btn-red {
  border: 0px;
  cursor: pointer;
  border-radius: 5px;
  color: #ecfcfa !important;
  background: #ff5349 !important;
}

#modalEventoSetores .btn-blue {
  border: 0px;
  cursor: pointer;
  border-radius: 5px;
  color: #ecfcfa !important;
  background: #00bcf1 !important;
}