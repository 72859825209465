.socioPage {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}

.socioPage .priceSocio {
  background-color: #ce2029;
  color: #FFF;
  border-radius: 8px;
  padding: 14px 16px 8px 16px;
  text-align: right;
}

.socioPage .priceSocio p {
  font-size: 16pt;
  font-weight: 600;
  line-height: 12px;
}

.socioPage .priceSocio small {
  font-size: 11px;
  font-weight: normal;
}

.socioPage .termoContent {
  white-space: pre-line;
  text-align: center;
  background-color: #FFF;
  border-radius: 8px;
  padding: 8px;
  width: 100%;
  overflow-y: auto;
  height: 250px;
}

.socioPage .btnAceito {
  border: none;
  cursor: pointer;
  border-radius: 8px;
  background-color: #6f66ff;
  color: #FFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  align-self: flex-end;
}

.socioPage .btnAceito:hover {
  background-color: #4f45d1;
}

.socioPage .inputForm {
  width: 300px;
  height: 40px;
  display: flex;
  flex-direction: row;
  background: #dedede;
  border-radius: 20px;
  align-items: center;
  padding: 0px 15px;
  gap: 8px;
}

.socioPage .inputForm svg {
  font-size: 20px;
}

.socioPage .inputForm input {
  width: 100%;
  height: 35px;
  border: none;
  outline: none;
  background: transparent;
  font-size: 16px;
}

.socioPage .listCards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
}

.socioPage .listCards .itemCard {
  cursor: pointer;
  padding: 12px 16px;
  border-radius: 8px;
  border: 2px dashed #8788b0;
  line-height: 24px;
  position: relative;
}

.socioPage .listCards .itemCard:hover {
  background-color: #FFF;
  border: 2px dashed #53547a;
}

.socioPage .listCards .itemCard:active {
  background-color: #eaf1ff;
}

.socioPage .listCards .itemCard .btnDelete {
  padding: 6px 8px;
  position: absolute;
  top: -15px;
  right: -15px;
  background-color: #ce2029;
  border: none;
  color: #FFF;
  border-radius: 8px;
}

.socioPage .listCards .itemCard .btnDelete:hover {
  background-color: #f0545c;
}

.socioPage .btnAddCard {
  font-size: 28px;
  margin-bottom: 12px;
  color: #18834d;
  cursor: pointer;
}

.socioPage .btnAddCard:hover {
  color: #299660;
}

.socioPage .btnAddCard:active {
  color: #0e4d2d;
}

.socioPage .formCartao {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.socioPage .formCartao select {
  background-color: #dedede;
  border: none;
  height: 35px;
  color: #8788b0;
}

.socioPage .cadeirasContent {
  display: flex;
  width: 100%;
  overflow-x: auto;
  padding: 5px;
}

.socioPage .cadeirasContent .listCadeiras {
  min-width: 920px;
  /* width: 920px; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
}

.socioPage .cadeirasContent .listCadeiras .btnCadeira {
  display: block;
  cursor: pointer;
  height: 20px;
  background: #6f66ff;
  border-radius: 4px;
}

.socioPage .cadeirasContent .listCadeiras .btnCadeira:hover {
  background: #8279ff;
}

.socioPage .cadeirasContent .listCadeiras .btnCadeira:active {
  background: rgb(86, 77, 204);
}

.socioPage .gramado {
  width: 100%;
  background-color: #0e4d2d;
  text-align: center;
  color: #FFF;
  padding: 15px 0px;
  border-radius: 8px;
}

.socioPage .acesso {
  width: 100%;
  background-color: #cdcdcd;
  text-align: center;
  padding: 15px 0px;
  border-radius: 8px;
}

.socioPage .infoArquibancada p {
  margin: 0px;
  padding: 0px;
  text-align: center;
  font-size: 14px;
}

.socioPage .ConfirmAssinatura {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: center;
  text-align: center;
}

.socioPage .infoSocio {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 12px;
}

.socioPage .infoSocio div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
  margin: 5px;
  border: 2px dashed #8788b2;
  border-radius: 8px;
  padding: 16px 8px;
  font-size: 24px;
  font-weight: 400;
}

@media only screen and (max-width: 767px) {
  .socioPage .infoSocio {
    width: 100%;
    flex-direction: column;
  }

  .socioPage .infoSocio div {
    width: 100%;
  }
}

.socioPage .infoSocio div b {
  font-weight: 600;
  font-size: 15px;
}

.socioPage .cancelAssinatura {
  cursor: pointer;
  background-color: #ce2029;
  color: #FFF;
  border-radius: 8px;
  border: 1px solid #7e0d12;
}