#pageEventosPorCidade .sectionTitle {
  font-family: Helvetica;
  text-align: center;
  font-size: 25px;
  font-weight: bold;
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#pageEventosPorCidade .sectionTitle p {
  border-top: 5px solid #ffcf00;
  border-bottom: 5px solid #ffcf00;
  padding: 5px 25px;
  margin-top: 15px;
}

#pageEventosPorCidade .contentCidades {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

#pageEventosPorCidade .contentCidades .cardCidade {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 50px;
  border-radius: 8px;
  background-color: #eee;
}

#pageEventosPorCidade .contentCidades .cardCidade:hover {
  border: 2px dashed #000;
  color: #000;
}

#pageEventosPorCidade .contentCidades .selected {
  background-color: #eb701e;
  color: #FFF !important;
  border: none !important;
}

#pageEventosPorCidade .contentEventos {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: center;
}

#pageEventosPorCidade .contentEventos .cardEvento {
  font-family: Helvetica;
  color: #262626;
  width: 160px;
  cursor: pointer;
}

#pageEventosPorCidade .contentEventos .cardEvento .poster {
  width: 100%;
  height: 205px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

#pageEventosPorCidade .contentEventos .cardEvento .info p:nth-child(1) {
  font-size: 16px;
  font-weight: 600;
  margin: 5px 0px 2px 0px;
  text-align: center;
}

#pageEventosPorCidade .contentEventos .cardEvento .info p:nth-child(2) {
  font-size: 16px;
  color: #199ff4;
  text-align: center;
}

#pageEventosPorCidade .pagination {
  background-color: #eee;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 10px;
  border-radius: 8px;
  width: 120px;
  margin: 20px auto 0px;
}

#pageEventosPorCidade .pagination p {
  margin: 0px 8px;
  font-size: 15px;
  font-weight: 500;
}