#pageEventosForm {
  display: flex;
  flex-direction: column;
  padding: 15px;
  min-width: 800px;
}

#pageEventosForm .body {
  margin-top: 25px;
  width: 100%;
  background: #1f2139;
  border-radius: 8px;
  box-shadow: 1px 1px 3px #18182c;
  padding: 15px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

#pageEventosForm .body span label {
  font-size: 14px;
  margin-left: 8px;
}

#pageEventosForm .body .btnContainer {
  width: 100%;
  margin: 0 auto;
  padding-top: 15px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

#pageEventosForm textarea {
  height: 80px;
  width: 100%;
  border: 0;
  border-radius: 5px;
  padding: 4px 16px;
  font-size: 14px;
  background: #63658b;
  color: #C0C0C0;
}

#pageEventosForm .btnContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 25px;
  justify-content: center;
  align-items: center;
}