#pageMeusIngressos .sectionTitle {
  font-family: Helvetica;
  text-align: center;
  font-size: 25px;
  font-weight: bold;
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#pageMeusIngressos .sectionTitle p {
  border-top: 5px solid #ffcf00;
  border-bottom: 5px solid #ffcf00;
  padding: 5px 25px;
  margin-top: 15px;
}

#pageMeusIngressos .ingressosContent {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  align-items: flex-start;
  margin-top: 15px;
}

#pageMeusIngressos .ingressosContent .cardIngresso {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 200px;
  gap: 5px;
  border-radius: 5px;
  overflow: hidden;
  padding: 0 5px;
  padding-bottom: 8px;
  background-color: #dfdfdf;
  color: #333;
  cursor: pointer;
}

#pageMeusIngressos .ingressosContent .cardIngresso img {
  width: 100%;
}

#pageMeusIngressos .ingressosContent .cardIngresso p {
  font-size: 22px;
  font-weight: 500;
}

#pageMeusIngressos .ingressosContent .cardIngresso span {
  font-size: 14px;
}

#pageMeusIngressos .ingressosContent .cardIngresso span strong {
  font-weight: 500;
  font-size: 16px;
}

#pageMeusIngressos .ingressosContent .cardIngresso .paid {
  width: 125px;
  padding: 5px;
  background-color: #e4f7c8;
  color: #4f7f00;
  margin: 0 auto;
  border-radius: 5px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
}

#pageMeusIngressos .ingressosContent .cardIngresso .used {
  width: 125px;
  padding: 5px;
  background-color: #f1d7c5;
  color: #eb701e;
  margin: 0 auto;
  border-radius: 5px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
}

#pageMeusIngressos .ingressosContent .cardIngresso .failed {
  width: 125px;
  padding: 5px;
  background-color: #fde0e7;
  color: #a71a44;
  margin: 0 auto;
  border-radius: 5px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
}

#pageMeusIngressos .ingressosContent .cardIngresso .pending {
  width: 125px;
  padding: 5px;
  background-color: #e2efff;
  color: #235894;
  margin: 0 auto;
  border-radius: 5px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
}