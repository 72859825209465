.form-control {
  height: 45px;
  width: 100%;
  border: solid 1px #343434;
  border-radius: 5px;
  padding: 4px 16px;
  font-size: 14px;
  background: #fff;
  color: #343434;
}

.alert-danger,
.alert-success {
  background-color: #fde0e7;
  color: #a71a44;
  border-radius: 5px;
  padding: 25px;
  margin-bottom: 25px;
  text-align: center;
}

.alert-success {
  background-color: #e0fde1;
  color: #1aa72d;
}

.btn-sorteio {
  color: #fff;
  background-color: #235894;
  height: 45px;
  width: auto;
  border: none;
  border-radius: 5px;
  padding: 4px 16px;
  font-size: 14px;
}
