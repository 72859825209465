#pageHome .eventos {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 15px;
}

#pageHome .eventos .cardEvento {
  margin-top: 15px;
  width: 360px;
  cursor: pointer;
}

#pageHome .eventos .cardEvento .poster {
  width: 100%;
  height: 410px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}

#pageHome .eventos .cardEvento .poster p {
  padding: 1px 10px;
  position: absolute;
  top: 35px;
  background-color: var(--yellow-500);
  color: #FFF;
  font-size: 28px;
  font-weight: 600;
  font-family: Helvetica;
}

#pageHome .eventos .cardEvento .info {
  display: flex;
  background-color: var(--yellow-500);
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: Helvetica;
}

#pageHome .eventos .cardEvento .info div:nth-child(1) {
  background: var(--blue-700);
  width: 250px;
  color: #FFF;
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
}

#pageHome .eventos .cardEvento .info div:nth-child(1) .title {
  font-size: 20px;
  font-weight: 600;
}

#pageHome .eventos .cardEvento .info div:nth-child(1) .local {
  font-size: 14px;
}

#pageHome .eventos .cardEvento .info div:nth-child(1) .hora {
  font-size: 20px;
  font-weight: 600;
}

#pageHome .eventos .cardEvento .info div:nth-child(2) {
  flex: 1;
  height: 100%;
  background-color: var(--yellow-500);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #FFF;
  font-weight: 600;
  font-size: 18px;
}

#pageHome .eventos .cardEvento .info div:nth-child(2) p {
  flex: 1;
  text-align: center;
}

#pageHome .eventos .cardEvento .info div:nth-child(2) svg {
  margin-left: -7px;
  color: #345bb7;
  font-size: 30px;
}

#pageHome .sectionTitle {
  font-family: Helvetica;
  margin-top: 25px;
  text-align: center;
  font-size: 25px;
  font-weight: bold;
  color: black;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#pageHome .sectionTitle p {
  border-top: 5px solid #345bb7;
  border-bottom: 5px solid #345bb7;
  padding: 5px 25px;
  margin-top: 15px;
}

#pageHome .sectionTitle p span {
  color: var(--yellow-500);
}

#pageHome .proxEventos {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: center;
}

#pageHome .proxEventos .cardProxEvento {
  font-family: Helvetica;
  color: #262626;
  width: 160px;
  cursor: pointer;
}

#pageHome .proxEventos .cardProxEvento .poster {
  width: 100%;
  height: 205px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

#pageHome .proxEventos .cardProxEvento .info p:nth-child(1) {
  font-size: 16px;
  font-weight: 600;
  margin: 5px 0px 2px 0px;
}

#pageHome .proxEventos .cardProxEvento .info p:nth-child(2) {
  font-size: 16px;
  color: #199ff4;
}