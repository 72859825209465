.minhaConta {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 16px
}

.minhaConta .menu {
  width: 25%;
  border-radius: 8px;
  background: #f1f1f1;
  padding: 8px;
  text-align: center;
}

.minhaConta .menu nav {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: left;
  padding: 2px 8px;
}

.minhaConta .menu nav a {
  text-decoration: none;
  color: #8788B0;
  cursor: pointer;
}

.minhaConta .menu nav a:hover {
  font-weight: 500;
}

.minhaConta .menu .cliName {
  font-size: 14pt;
  font-weight: 600;
}

.minhaConta .menu .cliPhone {
  font-size: 10pt;
}

.minhaConta .content {
  width: 80%;
  border-radius: 8px;
  background: #f1f1f1;
  padding: 8px;
}

@media only screen and (max-width: 767px) {
  .minhaConta {
    flex-direction: column;
  }

  .minhaConta .menu {
    width: 100%;
  }

  .minhaConta .content {
    width: 100%;
  }
}