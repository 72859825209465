#pageAguardandoPgto {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

#pageAguardandoPgto .divCopied {
  text-align: center;
  width: 80px;
  margin: 0px auto;
  padding: 5px 0px;
  border: 1px dashed #333;
  color: #333;
  border-radius: 5px;
  opacity: 0;
  transition: all 0.5s;
}